$( document ).on('turbolinks:load', function() {
  // desktop nav states
  $(".ts-desktop-nav-states-container").hover(function() {
    $(".ts-desktop-nav-states").toggleClass('d-none')
  })

  $(".ts-desktop-nav-states-container").click(function() {
    $(".ts-desktop-nav-states").toggleClass('d-none')
  })
  
  // nav burger menu
  $( ".ts-nav-burger" ).click(function() {
    $(".ts-nav-burger-menu").removeClass("d-none");
    $(".ts-nav-burger-menu").addClass("d-flex");
  });
  $( ".ts-nav-burger-close" ).click(function() {
    $(".ts-nav-burger-menu").removeClass("d-flex");
    $(".ts-nav-burger-menu").addClass("d-none");
  });

  // home influencer image height
  var influence_width = $(".influencer-image").width()
  $(".influencer-image").height(influence_width)

  // states
  $(".ts-slider-content-right-nav-btn").click(function() {
    // change button styles
    var nav_cont = $(this).parent()
    console.log(nav_cont)
    nav_cont.find(".ts-slider-content-right-nav-btn").removeClass('selected')
    $(this).addClass('selected')
    // set main id variable
    var id = $(this).attr("data-slider-btn")
    // change image
    var image_cont = $(this).parent().prev()
    image_cont.find(`.ts-slider-content-right-image`).addClass('d-none')
    image_cont.find(`[data-slider-image='${id}']`).removeClass('d-none')
    // change text
    var text_cont = $(this).parent().parent().prev()
    text_cont.find(`[data-slider-text]`).addClass('d-none')
    text_cont.find(`[data-slider-text='${id}']`).removeClass('d-none')
    text_cont.find(`[data-slider-text='${id}']`).addClass('d-flex')
  });

});